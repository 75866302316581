require("./bootstrap");
//require("./vue");
require("./helpers");
require("./sidebar");

// Initialize chosen on all fields with chosen class
$(".chosen").chosen();

// Initialize bootstrap-datepicker on all fields with datepicker class
$(".datepicker").datepicker({
    autoclose: true,
    todayHighlight: true,
    clearBtn: true,
    format: "yyyy-mm-dd",
    enableOnReadonly: false,
});

// https://stackoverflow.com/questions/10730362/get-cookie-by-name
window.getCookie = function(name) {
    var match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
    if (match) return match[2];
};

// Fade out the flash message after 10 seconds
setTimeout(() => {
    $("#flash_message").fadeOut( "slow", function() {
        $(document).find('#flash_message').remove();
    });
}, 7000);
// setTimeout(() => {
//     $("#flash_message").addClass("fadeOutRight");
//     $("#flash_message").one(animationEnd, function() {
//         $("#flash_message").remove();
//     });
// }, 7000);

$(".scroll-to").click(function(e) {
    e.preventDefault();
    var href = $(this).attr("href");

    $("html, body").animate(
        {
            scrollTop: $(href).offset().top - 75,
        },
        750
    );
});

// Reset general_modal when it closes.
$("#general_modal").on("hidden.bs.modal", function(e) {
    const $el = $(this);
    $el.find(".close, .modal-footer").show();
    $el.find(".modal-dialog")
        .removeClass()
        .addClass("modal-dialog");

    $("#general_modal_save_btn").text("Save");
    $("#general_modal_save_btn").unbind();
});

// iterate over each table header
$("th").each(function() {
    // if this header has the word 'date' in it
    if (
        $(this)
            .text()
            .toLowerCase()
            .indexOf("date") > -1
    ) {
        // add the nowrap css property
        $(this).css("white-space", "nowrap");
    }
});

$("#sidebar_search").autocomplete({
    serviceUrl: "/leases/autoComplete",
    // appendTo: $('#sidebar_search'),
    dataType: "json",
    triggerSelectOnValidInput: false,
    // deferRequestBy: 1000,
    minChars: 2,
    onSearchStart: function() {
        if (!$(".auto-complete-spinner").length) {
            $("#sidebar_search").after(
                '<i class="fas fa-spinner fa-spin auto-complete-spinner" style="position: absolute; top:32px; right: 45px;"></i>'
            );
        }
    },
    onSearchComplete: function() {
        $(document)
            .find(".auto-complete-spinner")
            .remove();
    },
    onSearchError: function() {
        $(document)
            .find(".auto-complete-spinner")
            .remove();
    },
    onSelect: function(suggestion) {
        window.location = suggestion.data;
    },
});
$('nav#sidebar').on('scroll', function(){ $('#sidebar_search').trigger('blur');});
$(document).on('scroll', function(){
    if(!($(this).is($('.autocomplete-suggestions').first()))){ $('#sidebar_search').trigger('blur');}
});

// Disable scrolling on type=number elements (chromium browsers)
document.addEventListener("mousewheel", function(event) {
    if (document.activeElement.type === "number") {
        document.activeElement.blur();
    }
});

window.confirmDelete = function(itemName, callback) {
    const $el = $("#confirm_delete_modal");

    $el.find(".item-name").text(itemName);

    $el.modal('show');
    //should remove event listeners already attached to confirm button
    $el.find(".confirm").off('click').click(function() {
        callback();
        $el.modal("hide");
    });
};
