/**
 * Copy of Excel's PMT function.
 * Credit: http://stackoverflow.com/questions/2094967/excel-pmt-function-in-js
 *
 * @param rate_per_period       The interest rate for the loan.
 * @param number_of_payments    The total number of payments for the loan in months.
 * @param present_value         The present value, or the total amount that a series of future payments is worth now;
 *                              Also known as the principal.
 * @param future_value          The future value, or a cash balance you want to attain after the last payment is made.
 *                              If fv is omitted, it is assumed to be 0 (zero), that is, the future value of a loan is 0.
 * @param type                  Optional, defaults to 0. The number 0 (zero) or 1 and indicates when payments are due.
 *                              0 = At the end of period
 *                              1 = At the beginning of the period
 * @returns {number}
 */
window.pmt = function(rate_per_period, number_of_payments, present_value, future_value = 0, type = 0) {
    if (rate_per_period != 0.0) {
        // Interest rate exists
        var q = Math.pow(1 + rate_per_period, number_of_payments);
        return -(rate_per_period * (future_value + (q * present_value))) / ((-1 + q) * (1 + rate_per_period * (type)));

    } else if (number_of_payments != 0.0) {
        // No interest rate, but number of payments exists
        return -(future_value + present_value) / number_of_payments;
    }

    return 0;
};

window.dtButtonSettings = function (csvFileName = '', csvExportColumns = '') {
    let settings = [
        { 
            extend: 'csvHtml5',
            text: '<i class="fas fa-file-excel"></i> CSV',
            className: 'btn-info',
            filename: csvFileName,
            exportOptions: {
                format: {
                    header: function (data) {
                        return data.substr(data.lastIndexOf('>')+1, data.length).trim();
                    }
                },
                columns: csvExportColumns
            }
        },
        { 
            extend: 'print',
            text: '<i class="fas fa-print"></i> Print',
            className: 'btn-info',
            exportOptions: {
                format: {
                    header: function (data) {
                        return data.substr(data.lastIndexOf('>')+1, data.length).trim();
                    }
                }
            }
        }
    ];

    if(csvFileName === '') {
        delete settings[0].filename;
    }

    if(csvExportColumns === '') {
        delete settings[0].exportOptions.columns;
    }

    return settings;
};

// https://github.com/daneden/animate.css
window.animationEnd = (function(el) {
    var animations = {
        animation: 'animationend',
        OAnimation: 'oAnimationEnd',
        MozAnimation: 'mozAnimationEnd',
        WebkitAnimation: 'webkitAnimationEnd',
    };

    for (var t in animations) {
        if (el.style[t] !== undefined) {
            return animations[t];
        }
    }
})(document.createElement('div'));