// Store state of left sidebar in session when the toggle button is clicked
$('.sidebar-toggle').click(function (e) {
    e.preventDefault();

    // Build the exp date for the cookie - set it to be a year from now
    var n = new Date();
    n.setFullYear(n.getFullYear() + 1);

    if(getCookie('sidebar-collapsed') === undefined) {
        document.cookie = 'sidebar-collapsed=false; expires='+n+'; path=/';
    }

    // JSON parse it so the string bool value is actually a boolean.
    var collapsed = JSON.parse(getCookie('sidebar-collapsed'));

    if(collapsed) {
        // remove the collapsed class from the sidebar
        $('.sidebar').removeClass('collapsed');
        // re add the bootstrap structure to main
        $('main').removeClass('expanded col').addClass('col-sm-9 ms-sm-auto col-md-10');
    }
    else {
        $('.sidebar').addClass('collapsed');
        $('main').removeClass('col-sm-9 ms-sm-auto col-md-10').addClass('expanded col');
    }

    // Set the cookie
    document.cookie = 'sidebar-collapsed='+!collapsed+'; expires='+n+'; path=/';
});

// When clicking the search icon in the collapsed sidebar,
// re open the sidebar and focus the search input.
$('.collapsed_search').click(function (e) {
    e.preventDefault();
    $('.sidebar-toggle').click();
    $('#sidebar_search').focus();
});
